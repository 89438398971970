<template>
  <div
    v-editable="blok"
    class="constrain relative z-20 bg-light-green lg:bg-transparent"
  >
    <Breadcrumbs
      v-if="hasBreadcrumbs"
      class="py-4 lg:py-16"
      :breadcrumbs="blok.breadcrumbs"
    />

    <div
      class="flex lg:gap-x-20"
      :class="{
        'pb-6 md:pb-10 xl:pb-24': hasBreadcrumbs,
        'py-16': !hasBreadcrumbs,
      }"
    >
      <!-- Text Section -->
      <div
        class="w-full lg:w-6/12 flex flex-col justify-center items-center lg:items-start relative z-10"
      >
        <div
          class="h-full text-center lg:text-left max-w-md md:mx-auto lg:max-w-full lg:mx-0"
        >
          <h1 class="heading-1">{{ blok.title }}</h1>
          <p class="text-neutrals-900 mt-2">{{ blok.subtitle }}</p>

          <!-- Centering the BookingPostcode component below lg -->
          <div
            class="w-full flex justify-center lg:justify-start md:mt-5"
          >
            <BookingPostcode class="w-full max-w-md" />
          </div>
        </div>

        <TrustpilotGreenBlock />
      </div>

      <!-- Image Section -->
      <div class="hidden lg:block lg:w-6/12 flex relative z-10">
        <NuxtImg
          provider="storyblok"
          class="w-full h-full rounded-3xl object-cover"
          :src="blok.image.filename"
          :alt="blok.image.alt"
          width="600px"
          loading="lazy"
          format="webp"
        />
      </div>
    </div>
  </div>

  <Gradients :gradients="blok.gradients" />
</template>
<script setup lang="ts">
const props = defineProps({ blok: Object });

const hasBreadcrumbs = computed(() => {
  return props.blok.breadcrumbs && props.blok.breadcrumbs.length > 0;
});
</script>
